var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("a-date-picker", {
    attrs: {
      dropdownClassName: "j-date-picker",
      disabled: _vm.disabled || _vm.readOnly,
      placeholder: _vm.placeholder,
      value: _vm.momVal,
      showTime: _vm.showTime,
      format: _vm.dateFormat,
      getCalendarContainer: _vm.getCalendarContainer,
    },
    on: { change: _vm.handleDateChange },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }